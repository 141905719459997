<template>
  <!-- 사업자 정보 -->
  <div class="info-container" v-if="infoData">
    <!-- 헤더 -->
    <div class="info-header-wrap flex-space-between">
      <div class="flex-align-center">
        <span class="header-category">사업자 정보</span>
        <div v-if="!isAdmin">
          <span :class="`header-status-${getApprovalStatus()}`">{{
            approvalStatus
          }}</span>
          <span
            class="header-status-date"
            v-if="approvalStatus === '처리중'"
            @click="openBusinessModal"
            >{{ regDate }}</span
          >
          <span
            class="header-status-date"
            @click="openBusinessModal"
            v-if="
              approvalStatus === '승인완료' || approvalStatus === '승인거절'
            "
            >{{ approvalDate }}</span
          >
          <span
            class="header-status-reason"
            v-if="approvalStatus === '승인거절'"
            @click="openRejectReason"
            >거절사유보기 ></span
          >
        </div>
      </div>
      <div class="flex-align-center" v-if="isEdit">
        <span
          class="mr-8"
          style="font-size: 12px; color: #f1431d"
          v-if="!checkValid"
          >모든 항목을 입력해주세요.</span
        >
        <button-common
          class="mr-8"
          :size="SIZE_T"
          :clr="CLR_W"
          @click="clickCancel"
          >취소</button-common
        >
        <button-common
          :size="SIZE_T"
          :clr="CLR_M"
          :disabled="!checkValid"
          @click="clickEdit"
          >수정신청</button-common
        >
      </div>
      <button-common :size="SIZE_T" :clr="CLR_M" @click="isEdit = true" v-else
        >수정</button-common
      >
    </div>
    <!-- 설명 -->
    <div class="info-notice" v-if="isEdit">
      등록 내용은 사업자 등록증의 내용과 동일하게 작성해주세요. 첨부해주신
      사업자등록증과 내용이 다를 경우 승인 및 변경이 불가능합니다.
    </div>
    <!-- 상호명 -->
    <div class="info-content-wrap">
      <span class="content-category">상호명</span>
      <input-text
        placeholder="회사이름"
        style="width: 500px"
        v-model="editData.compName"
        v-if="isEdit"
      />
      <span v-else>{{ infoData.compName }}</span>
    </div>
    <div
      class="info-content-wrap"
      style="padding: 0; margin-top: -10px"
      v-if="isEdit"
    >
      <span class="content-category"></span>
      <p style="color: #828699; font-size: 12px">
        사업자 등록증의 상호명과 동일하게 입력해 주세요.
      </p>
    </div>
    <!-- 성명(대표자명) -->
    <div class="info-content-wrap">
      <span class="content-category">성명(대표자명)</span>
      <input-text
        placeholder="대표자이름"
        :disabled="!isEditBusiness"
        v-model="editData.compCeoName"
        v-if="isEdit"
      />
      <span v-else>{{ infoData.compCeoName }}</span>
    </div>
    <!-- 개업연월일 -->
    <div class="info-content-wrap" v-if="isEdit && isEditBusiness">
      <span class="content-category">개업연월일</span>
      <div class="flex-align-center">
        <!-- <input-text
          placeholder="개업연월일 YYYY.MM.DD"
          v-model="createDt"
          class="mr-16"
        /> -->
        <div class="align-items-center">
          <v-date-picker ref="datepicker" v-model="createDt">
            <template v-slot="{ inputEvents }">
              <div class="flex justify-center items-center">
                <input
                  class="date-input mr-16"
                  :value="formatDate(createDt)"
                  v-on="inputEvents"
                />
              </div>
            </template>
          </v-date-picker>
        </div>
        <span style="font-size: 12px; color: #828699"
          >사업자등록증에 표기된 개업연월일 날짜로 입력해주세요.<br />개업일자
          사업자 확인을 위한 입력정보이며 별도 저장되지 않습니다.</span
        >
      </div>
    </div>
    <!--사업자등록번호 -->
    <div class="info-content-wrap">
      <span class="content-category">사업자등록번호</span>
      <div class="flex-align-center" v-if="isEdit">
        <input-text
          placeholder="사업자 등록번호 10자리"
          :disabled="!isEditBusiness"
          v-model="editData.compRegNumber"
          class="mr-16"
        />
        <button-common
          class="mr-16"
          :size="SIZE_T"
          :clr="CLR_M"
          @click="isEditBusiness = true"
          v-if="!isEditBusiness"
          >{{ compRegNumberDummy ? "수정" : "입력" }}</button-common
        >
        <button-common
          class="mr-16"
          :size="SIZE_T"
          :clr="CLR_M"
          :disabled="!checkBusinessValid"
          @click="checkBusinessData"
          v-if="isEditBusiness"
          >사업자 확인</button-common
        >
        <button-common
          class="mr-16"
          :size="SIZE_T"
          :clr="CLR_W"
          @click="cancelEditBusiness"
          v-if="isEditBusiness"
          >취소</button-common
        >
        <span
          class="business-info"
          style="color: #2ccdc3"
          v-if="!isFirst && isCheck"
          >사업자 확인 완료</span
        >
        <span
          class="business-info"
          style="color: #fd765f"
          v-if="!isFirst && !isCheck"
          >상호명, 대표자명, 개업일자, 사업자 번호를 다시한번 확인 후 ‘사업자
          확인’ 버튼을 눌러주세요.</span
        >
      </div>
      <span v-else>{{ infoData.compRegNumber }}</span>
    </div>
    <div
      class="info-content-wrap"
      style="padding: 0; margin-top: -10px"
      v-if="isEdit"
    >
      <span class="content-category"></span>
      <p style="color: #828699; font-size: 12px">
        상호, 성명, 사업자등록번호 등록 및 수정은 사업자등록정보 인증이
        필요합니다.
      </p>
    </div>
    <!-- 사업장소재지 -->
    <div class="info-content-wrap">
      <span class="content-category">사업장소재지</span>
      <div v-if="isEdit">
        <div class="flex-align-center">
          <input-text
            placeholder="사업장 주소"
            v-model="editData.compAddr"
            disabled
            class="mr-16"
            style="width: 350px"
          />
          <button-common :size="SIZE_T" :clr="CLR_M" @click="openAddress"
            >주소검색</button-common
          >
        </div>
        <input-text
          placeholder="상세주소"
          v-model="editData.compAddr2"
          class="mt-16"
        />
      </div>
      <span v-else>{{ infoData.compAddr }} {{ infoData.compAddr2 }}</span>
    </div>
    <!-- 사업자 구분 -->
    <div class="info-content-wrap">
      <span class="content-category">사업자 구분</span>
      <div v-if="isEdit">
        <select-box
          style="width: 200px; text-align: left"
          v-model="editData.compClass"
          :opt-nm="compClassList"
          :size="SIZE_M"
        />
      </div>
      <span v-else>{{ infoData.compClass }}</span>
    </div>
    <!-- 업태 -->
    <div class="info-content-wrap">
      <span class="content-category">업태</span>
      <input-text
        placeholder="업태"
        v-model="editData.compStatus"
        v-if="isEdit"
      />
      <span v-else>{{ infoData.compStatus }}</span>
    </div>
    <!-- 업종 -->
    <div class="info-content-wrap">
      <span class="content-category">업종</span>
      <input-text
        placeholder="업종"
        v-model="editData.compType"
        v-if="isEdit"
      />
      <span v-else>{{ infoData.compType }}</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "BusinessInfo",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  /*** data ***/
  data() {
    return {
      infoData: null,
      previewEditData: null,
      editData: {
        compName: "",
        compRegNumber: "",
        compCeoName: "",
        compAddr: "",
        compAddr2: "",
        compClass: "",
        compStatus: "",
        compType: "",
      },
      isEdit: false,
      compClassList: [
        { title: "개인사업자(일반과세)", value: "개인사업자(일반과세)" },
        { title: "개인사업자(간이과세)", value: "개인사업자(간이과세)" },
        { title: "법인사업자", value: "법인사업자" },
      ],
      approvalStatus: null,
      approvalDate: null,
      regDate: null,
      rejectReason: "",
      isAdmin: false,
      createDt: "",
      isFirst: true,
      isCheck: false,
      isEditBusiness: false,
      compCeoNameDummy: null,
      compRegNumberDummy: null,
    };
  },

  /*** created ***/
  async mounted() {
    if (this.$route.params.id) {
      this.isAdmin = true;
      await this.getBusinessAdminData();
    } else {
      this.isAdmin = false;
      await this.getBusinessData();
    }
  },

  /*** computed ***/
  computed: {
    checkValid() {
      return (
        this.editData.compName &&
        this.editData.compRegNumber &&
        this.editData.compCeoName &&
        this.editData.compAddr &&
        this.editData.compAddr2 &&
        this.editData.compClass &&
        this.editData.compStatus &&
        this.editData.compType &&
        (!this.isEditBusiness || this.isCheck)
      );
    },
    checkBusinessValid() {
      return (
        this.editData.compRegNumber &&
        this.editData.compCeoName &&
        this.createDt
      );
    },
  },

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    async getBusinessAdminData() {
      const rs = await this.$axios({
        url: "/admin/partner/reg/info",
        params: { esntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.infoData = rs.resultData[0];
        this.setEditData();
      } else {
        console.log("사업자정보 조회 실패");
      }
    },
    async getBusinessData() {
      const rs = await this.$axios({
        url: "/partner/myInfo/comp/data",
        params: {},
      });
      if (rs.result === "SUC") {
        this.infoData = rs.resultData.infoData[0];
        this.previewEditData = rs.resultData.editData;
        this.setEditData();
        if (this.previewEditData) {
          switch (this.previewEditData.approvalStatus) {
            case "APP":
              this.approvalStatus = "승인완료";
              break;
            case "REJ":
              this.approvalStatus = "승인거절";
              break;
            default:
              this.approvalStatus = "처리중";
          }
          this.approvalDate = this.previewEditData.approvalDate;
          this.regDate = this.previewEditData.regDate;
          this.rejectReason = this.previewEditData.rejectReason;
        } else {
          this.approvalStatus = null;
          this.approvalDate = null;
        }
      } else {
        console.log("사업자정보 조회 실패");
      }
    },
    setEditData() {
      this.editData.compName = this.infoData?.compName;
      this.editData.compRegNumber = this.infoData?.compRegNumber;
      this.compRegNumberDummy = this.infoData?.compRegNumber;
      this.editData.compCeoName = this.infoData?.compCeoName;
      this.compCeoNameDummy = this.infoData?.compCeoName;
      this.editData.compAddr = this.infoData?.compAddr;
      this.editData.compAddr2 = this.infoData?.compAddr2;
      this.editData.compClass = this.infoData?.compClass;
      this.editData.compStatus = this.infoData?.compStatus;
      this.editData.compType = this.infoData?.compType;
    },
    cancelEditBusiness() {
      this.editData.compCeoName = JSON.parse(
        JSON.stringify(this.compCeoNameDummy)
      );
      this.editData.compRegNumber = JSON.parse(
        JSON.stringify(this.compRegNumberDummy)
      );
      this.isEditBusiness = false;
      this.isFirst = true;
      this.isCheck = false;
    },
    async openAddress() {
      await this.$openAddress(
        {
          cbPrmy: this.cbSelectAddress,
        },
        this
      );
    },
    cbSelectAddress(address) {
      this.editData.compAddr = address;
    },
    async openBusinessModal() {
      await this.$openBusinessInfo({}, this);
    },
    async openRejectReason() {
      this.$store.dispatch("A_REJECT_REASON_DISABLED", true);
      this.$store.dispatch("A_REJECT_REASON", this.rejectReason);
      await this.$openRejectReason({}, this);
    },
    async clickEdit() {
      if (this.$route.params.id) {
        await this.editBusinessAdminData();
      } else {
        await this.editBusinessData();
      }
    },
    async editBusinessAdminData() {
      const data = JSON.parse(JSON.stringify(this.editData));
      data.regDate = this.infoData.regDate;
      data.esntlId = this.$route.params.id;
      const rs = await this.$axios({
        url: "/admin/partner/reg/info/comp/edit",
        params: data,
      });
      if (rs.result === "SUC") {
        await this.getBusinessAdminData();
        this.isEdit = false;
      } else {
        console.log("사업자정보 승인 신청 실패");
      }
    },
    async editBusinessData() {
      const rs = await this.$axios({
        url: "/partner/myInfo/comp/update",
        params: this.editData,
      });
      if (rs.result === "SUC") {
        await this.getBusinessData();
        this.isEdit = false;
      } else {
        console.log("사업자정보 승인 신청 실패");
      }
    },
    async checkBusinessData() {
      try {
        const date = this.$moment(this.createDt).format("YYYYMMDD");
        const business = [
          {
            b_no: this.editData.compRegNumber,
            start_dt: date,
            p_nm: this.editData.compCeoName,
            p_nm2: "",
            b_nm: this.editData.compName,
            corp_no: "",
            b_sector: "",
            b_type: "",
          },
        ];
        const rs = await axios({
          method: "POST",
          url: "https://api.odcloud.kr/api/nts-businessman/v1/validate?serviceKey=fbIbTHxA7jm%2BFEyA8e8BEhfDU%2FjOUpIWYMTv8Eqs3UCrhesF4BnoGzkto56IsEwJrp4t2fX9op44m4t67dD%2BSg%3D%3D",
          data: JSON.stringify({
            businesses: business,
          }),
          proxy: true,
          withCredentials: false,
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "fbIbTHxA7jm+FEyA8e8BEhfDU/jOUpIWYMTv8Eqs3UCrhesF4BnoGzkto56IsEwJrp4t2fX9op44m4t67dD+Sg==",
          },
        });
        this.isFirst = false;
        if (rs.data.data.length > 0) {
          this.isCheck = rs.data.data[0].valid === "01";
        } else {
          this.isCheck = false;
        }
      } catch (err) {
        this.isFirst = false;
        this.isCheck = false;
      }
    },
    getApprovalStatus() {
      switch (this.approvalStatus) {
        case "승인완료":
          return "complete";
        case "승인거절":
          return "reject";
        case "처리중":
          return "process";
      }
    },
    clickCancel() {
      this.isEdit = false;
      this.isEditBusiness = false;
      this.isFirst = true;
      this.isCheck = false;
      this.createDt = "";
    },
    formatDate(date) {
      return date ? this.$moment(date).format("YYYY.MM.DD") : "";
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  margin-top: 10px;
}
.info-header-wrap {
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid #d8dae6;
}
.info-notice {
  background: #f8f8fb;
  padding: 8px 32px;
  font-size: 12px;
  color: #828699;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.header-status-complete {
  color: #2CCDC3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-reject {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-process {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 16px 0;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  padding-left: 32px;
  width: 200px;
}
.business-info {
  font-weight: 500;
  font-size: 12px;
  color: #828699;
}
.date-input {
  height: 36px;
  padding: 8px;
  font-size: 14px;
  background: var(--gray10);
  border: 1px solid var(--gray20);
  color: var(--gray100);
  vertical-align: middle;
  position: relative;
  z-index: 2;
  overflow-y: auto;
}
</style>
