<template>
  <!-- 기본정보 -->
  <div class="info-container" v-if="infoData">
    <!-- 헤더 -->
    <div class="info-header-wrap flex-space-between">
      <div class="flex-align-center">
        <span class="header-category">기본정보</span>
        <div v-if="!isAdmin">
          <span :class="`header-status-${getApprovalStatus()}`">{{
            approvalStatus
          }}</span>
          <span
            class="header-status-date"
            v-if="approvalStatus === '처리중'"
            @click="openBasicModal"
            >{{ regDate }}</span
          >
          <span
            class="header-status-date"
            @click="openBasicModal"
            v-if="
              approvalStatus === '승인완료' || approvalStatus === '승인거절'
            "
            >{{ approvalDate }}</span
          >
          <span
            class="header-status-reason"
            v-if="approvalStatus === '승인거절'"
            @click="openRejectReason"
            >거절사유보기 ></span
          >
        </div>
      </div>
      <div class="flex-align-center" v-if="isEdit">
        <span
          class="mr-8"
          style="font-size: 12px; color: #f1431d"
          v-if="!checkValid"
          >모든 항목을 입력해주세요.</span
        >
        <button-common
          class="mr-8"
          :size="SIZE_T"
          :clr="CLR_W"
          @click="clickCancel"
          >취소</button-common
        >
        <button-common
          :disabled="!checkValid"
          :size="SIZE_T"
          :clr="CLR_M"
          @click="clickEdit"
          >수정신청</button-common
        >
      </div>
      <button-common :size="SIZE_T" :clr="CLR_M" @click="isEdit = true" v-else
        >수정</button-common
      >
    </div>
    <!-- 이름 -->
    <div class="info-content-wrap">
      <span class="content-category">이름</span>
      <span>{{ infoData.name }} {{ infoData.gender }}</span>
    </div>
    <!-- 로그인 ID -->
    <div class="info-content-wrap">
      <span class="content-category">로그인 ID</span>
      <span>{{ infoData.email }}</span>
    </div>
    <!-- 생년월일 -->
    <div class="info-content-wrap">
      <span class="content-category">생년월일</span>
      <span>{{ infoData.birth }}</span>
    </div>
    <!-- 휴대폰번호 -->
    <div class="info-content-wrap">
      <span class="content-category">휴대폰번호</span>
      <div class="flex-align-center" v-if="isEdit">
        <input-text
          placeholder="휴대폰 번호 입력"
          :disabled="!isEditPhone && !isAdmin"
          class="mr-16"
          v-model="editData.phone"
        />
        <button-common
          class="mr-16"
          :size="SIZE_T"
          :clr="CLR_M"
          @click="isEditPhone = true"
          v-if="!isEditPhone && !isAdmin"
          >수정</button-common
        >
        <button-common
          class="mr-16"
          style="width: 120px"
          :size="SIZE_T"
          :clr="CLR_M"
          :disabled="!checkPhoneValid"
          @click="sendPhoneNum"
          v-if="isEditPhone"
          >인증번호 요청</button-common
        >
        <button-common
          class="mr-16"
          :size="SIZE_T"
          :clr="CLR_W"
          @click="cancelEditPhone"
          v-if="isEditPhone"
          >취소</button-common
        >
        <span style="font-size: 12px; color: #828699" v-if="!isEditPhone"
          >휴대폰 번호는 본인인증 후 변경이 가능합니다.</span
        >
      </div>
      <span v-if="infoData.phone && !isEdit">{{
        formatPhone(infoData.phone)
      }}</span>
    </div>
    <!-- 본인인증 확인 -->
    <div class="info-content-wrap" v-if="isEdit && isEditPhone">
      <span class="content-category"></span>
      <div class="flex-align-center">
        <input-text
          placeholder="인증번호 입력"
          class="mr-16"
          v-model="phoneCheck"
        />
        <button-common
          class="mr-16"
          :size="SIZE_T"
          :clr="CLR_M"
          :disabled="
            time === 0 || !isSend || phoneCheck.length !== 6 || isCheck
          "
          @click="checkPhoneNum"
          >확인</button-common
        >
        <span
          class="business-info"
          style="color: #2ccdc3"
          v-if="time !== 0 && isSend && !isCheck"
          >{{ formatTime() }}</span
        >
        <span
          class="business-info"
          style="color: #fd765f"
          v-if="time === 0 && isSend && !isCheck"
          >인증번호를 재요청해주세요.</span
        >
        <span
          class="business-info"
          style="color: #2ccdc3"
          v-if="!isFirst && isCheck"
          >인증완료</span
        >
        <span
          class="business-info"
          style="color: #fd765f"
          v-if="!isFirst && !isCheck && time !== 0"
          >인증실패</span
        >
      </div>
    </div>
    <!-- 거주지역 -->
    <div class="info-content-wrap">
      <span class="content-category">거주지역</span>
      <div v-if="isEdit">
        <div class="flex-align-center">
          <input-text
            disabled
            class="mr-16"
            style="width: 350px"
            v-model="editData.addr"
          />
          <button-common :size="SIZE_T" :clr="CLR_M" @click="openAddress"
            >주소검색</button-common
          >
        </div>
        <input-text class="mt-16" v-model="editData.addr2" />
      </div>
      <span v-else>{{ infoData.addr }} {{ infoData.addr2 }}</span>
    </div>
    <!-- 분야 -->
    <div class="info-content-wrap">
      <span class="content-category">분야</span>
      <div class="flex-align-center" v-if="isEdit">
        <select-box
          class="mr-8"
          :opt-nm="highCategoryList"
          :size="SIZE_M"
          v-model="editData.highSpecialtyEsntlId"
          @input="getLowCategory()"
        />
        <select-box
          class="mr-8"
          :opt-nm="lowCategoryList"
          :size="SIZE_M"
          :disabled="!editData.highSpecialtyEsntlId"
          v-model="editData.lowSpecialtyEsntlId"
        />
        <input-text placeholder="기타" v-model="editData.orderSpecialty" />
      </div>
      <span v-else
        >{{
          infoData.highSpecialtyTitle ? infoData.highSpecialtyTitle : "없음"
        }}
        >
        {{ infoData.lowSpecialtyTitle ? infoData.lowSpecialtyTitle : "없음" }} >
        {{ editData.orderSpecialty ? editData.orderSpecialty : "없음" }}</span
      >
    </div>
    <!-- 경력 -->
    <div class="info-content-wrap">
      <span class="content-category">경력</span>
      <select-box
        :opt-nm="SELT_CAREER"
        class="category-select"
        style="width: 140px"
        v-model="editData.career"
        v-if="isEdit"
      />
      <span v-if="!isEdit && infoData.career">{{ infoData.career }}</span>
    </div>
    <!-- 자격증명 URL -->
    <div class="info-content-wrap">
      <span class="content-category">자격증명 URL</span>
      <input-text v-model="editData.proofUrl" v-if="isEdit" />
      <span
        style="text-decoration: underline; cursor: pointer"
        @click="clickProofUrl(infoData.proofUrl)"
        v-else
        >{{ infoData.proofUrl }}</span
      >
    </div>
    <!-- 결제항목 파일 -->
    <div class="info-content-wrap">
      <span class="content-category">결제항목 파일</span>
      <div class="flex-align-center" v-if="isEdit">
        <input
          accept="*"
          type="file"
          class="d-none"
          ref="fileRef"
          @input="handleUpload"
        />
        <button-common
          class="mr-16"
          :size="SIZE_T"
          :clr="CLR_M"
          @click="$refs.fileRef.click()"
          >파일첨부</button-common
        >
        <span style="text-decoration: underline">{{ editFileOriName }}</span>
      </div>
      <span
        style="text-decoration: underline; cursor: pointer"
        @click="downloadFile"
        v-else
        >{{ infoData.proofFileOri }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "BasicInfo",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  /*** data ***/
  data() {
    return {
      infoData: null,
      previewEditData: null,
      editData: {
        name: "",
        email: "",
        birth: "",
        gender: "",
        phone: "",
        addr: "",
        addr2: "",
        highSpecialtyEsntlId: "",
        lowSpecialtyEsntlId: "",
        orderSpecialty: "",
        career: "",
        proofUrl: "",
        proofFile: null,
      },
      isEdit: false,
      approvalStatus: null,
      approvalDate: null,
      regDate: null,
      editFileOriName: "",
      highCategoryList: [],
      lowCategoryList: [],
      rejectReason: "",
      isAdmin: false,
      isEditPhone: false,
      phoneDummy: "",
      phoneCheck: "",
      isFirst: true,
      isSend: false,
      isCheck: false,
      time: 0,
      interval: null,
    };
  },

  /*** computed ***/
  computed: {
    checkValid() {
      return (
        this.editData.phone &&
        this.editData.addr &&
        this.editData.addr2 &&
        this.checkCategoryValid &&
        this.editData.career &&
        this.editData.proofUrl &&
        this.editFileOriName &&
        (!this.isEditPhone || this.isCheck)
      );
    },
    checkPhoneValid() {
      let regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
      return regPhone.test(this.editData.phone);
    },
    checkCategoryValid() {
      return (
        this.editData.orderSpecialty.length > 0 ||
        (this.editData.highSpecialtyEsntlId &&
          this.editData.lowSpecialtyEsntlId)
      );
    },
  },

  /*** created ***/
  async mounted() {
    await this.getHighCategory();
    if (this.$route.params.id) {
      this.isAdmin = true;
      await this.getBasicAdminData();
    } else {
      this.isAdmin = false;
      await this.getBasicData();
    }
  },

  /*** watch ***/
  watch: {
    time() {
      if (this.time === 0) {
        clearInterval(this.interval);
      }
    },
  },

  /*** methods ***/
  methods: {
    async sendPhoneNum() {
      clearInterval(this.interval);
      this.isCheck = false;
      const rs = await this.$axios({
        url: "/partner/reg/certReq",
        params: { phone: this.editData.phone },
      });
      this.isSend = rs.result === "SUC";
      if (rs.result === "SUC") {
        this.isFirst = true;
        this.setTimer();
      }
      if (rs.result === "DUPL") {
        await this.openDuplicate();
      }
    },
    async checkPhoneNum() {
      const rs = await this.$axios({
        url: "/partner/reg/cert",
        params: { certNumber: this.phoneCheck },
      });
      this.isFirst = false;
      this.isCheck = rs.result === "SUC";
      if (this.isCheck) {
        clearInterval(this.interval);
      }
      if (rs.result === "DUPL") {
        await this.openDuplicate();
      }
    },
    cancelEditPhone() {
      this.editData.phone = JSON.parse(JSON.stringify(this.phoneDummy));
      this.phoneCheck = "";
      this.isEditPhone = false;
      this.isFirst = true;
      this.isSend = false;
      this.isCheck = false;
      clearInterval(this.interval);
    },
    async getHighCategory() {
      const rs = await this.$axios({
        url: "/specialty",
        params: { highEsntlId: "" },
      });
      if (rs.result === "SUC") {
        this.highCategoryList = rs.resultList.map((item) => {
          return { title: item.title, value: item.esntlId };
        });
        this.highCategoryList.unshift({
          title: "상위분야 선택안함",
          value: "",
        });
      }
    },
    async getLowCategory() {
      const rs = await this.$axios({
        url: "/specialty",
        params: { highEsntlId: this.editData.highSpecialtyEsntlId },
      });
      if (rs.result === "SUC") {
        this.lowCategoryList = rs.resultList.map((item) => {
          return { title: item.title, value: item.esntlId };
        });
        if (!this.editData.highSpecialtyEsntlId) {
          this.editData.lowSpecialtyEsntlId = "";
        }
        this.lowCategoryList.unshift({ title: "하위분야 선택안함", value: "" });
      }
    },
    async getBasicAdminData() {
      const rs = await this.$axios({
        url: "/admin/partner/reg/info",
        params: { esntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.infoData = rs.resultData[0];
        this.setEditData();
        this.getLowCategory();
      } else {
        console.log("기본정보 조회 실패");
      }
    },
    async getBasicData() {
      const rs = await this.$axios({
        url: "/partner/myInfo/basic/data",
        params: {},
      });
      if (rs.result === "SUC") {
        this.infoData = rs.resultData.infoData[0];
        this.previewEditData = rs.resultData.editData;
        this.setEditData();
        this.getLowCategory();
        if (this.previewEditData) {
          switch (this.previewEditData.approvalStatus) {
            case "APP":
              this.approvalStatus = "승인완료";
              break;
            case "REJ":
              this.approvalStatus = "승인거절";
              break;
            default:
              this.approvalStatus = "처리중";
          }
          this.approvalDate = this.previewEditData.approvalDate;
          this.regDate = this.previewEditData.regDate;
          this.rejectReason = this.previewEditData.rejectReason;
        } else {
          this.approvalStatus = null;
          this.approvalDate = null;
        }
      } else {
        console.log("기본정보 조회 실패");
      }
    },
    setEditData() {
      this.editData.name = this.infoData?.name;
      this.editData.email = this.infoData?.email;
      this.editData.birth = this.infoData?.birth;
      this.editData.gender = this.infoData?.gender;
      this.editData.phone = this.infoData?.phone;
      this.phoneDummy = this.infoData?.phone;
      this.editData.addr = this.infoData?.addr;
      this.editData.addr2 = this.infoData?.addr2;
      this.editData.highSpecialtyEsntlId =
        this.infoData?.highSpecialtyEsntlId ?? "";
      this.editData.lowSpecialtyEsntlId =
        this.infoData?.lowSpecialtyEsntlId ?? "";
      this.editData.orderSpecialty = this.infoData?.orderSpecialty ?? "";
      this.editData.career = this.infoData?.career;
      this.editData.proofUrl = this.infoData?.proofUrl;
      this.editData.proofFile = this.infoData?.proofFile;
      this.editFileOriName = this.infoData?.proofFileOri;
    },
    setTimer() {
      this.time = 180;
      this.interval = setInterval(() => {
        this.time = this.time - 1;
        if (this.time === 0) {
          clearInterval(this.interval);
        }
      }, 1000);
    },
    formatTime() {
      if (this.time === 0) {
        return "00:00";
      } else {
        let min = Math.floor(this.time / 60);
        let sec = this.time % 60;
        if (min < 10) {
          min = "0" + min;
        }
        if (sec < 10) {
          sec = "0" + sec;
        }
        return min + ":" + sec;
      }
    },
    async openDuplicate() {
      await this.$openAlert(
        {
          bodyTx:
            "이미 동일한 번호로 등록된 내역이 있습니다.<br/>다른 번호를 등록해주세요.",
          btnScndNm: "닫기",
        },
        this
      );
    },
    async openAddress() {
      await this.$openAddress(
        {
          cbPrmy: this.cbSelectAddress,
        },
        this
      );
    },
    cbSelectAddress(address) {
      this.editData.addr = address;
    },
    async openBasicModal() {
      await this.$openBasicInfo({}, this);
    },
    async openRejectReason() {
      this.$store.dispatch("A_REJECT_REASON_DISABLED", true);
      this.$store.dispatch("A_REJECT_REASON", this.rejectReason);
      await this.$openRejectReason({}, this);
    },
    async clickEdit() {
      if (this.$route.params.id) {
        await this.editBasicAdminData();
      } else {
        await this.editBasicData();
      }
    },
    isEditData(key) {
      return this.infoData[key] !== this.editData[key];
    },
    async editBasicAdminData() {
      const formData = new FormData();
      formData.append("regDate", this.infoData.regDate);
      formData.append("esntlId", this.infoData.esntlId);
      if (this.isEditData("phone")) {
        formData.append("phone", this.editData.phone);
      }
      if (this.isEditData("addr")) {
        formData.append("addr", this.editData.addr);
      }
      if (this.isEditData("addr2")) {
        formData.append("addr2", this.editData.addr2);
      }
      if (this.isEditData("highSpecialtyEsntlId")) {
        formData.append(
          "highSpecialtyEsntlId",
          this.editData.highSpecialtyEsntlId
        );
      }
      if (this.isEditData("lowSpecialtyEsntlId")) {
        formData.append(
          "lowSpecialtyEsntlId",
          this.editData.lowSpecialtyEsntlId
        );
      }
      if (this.isEditData("orderSpecialty")) {
        formData.append("orderSpecialty", this.editData.orderSpecialty);
      }
      if (this.isEditData("career")) {
        formData.append("career", this.editData.career);
      }
      if (this.isEditData("proofUrl")) {
        formData.append("proofUrl", this.editData.proofUrl);
      }
      if (this.isEditData("proofFile")) {
        formData.append("proofFile", this.editData.proofFile);
      }
      const rs = await this.$axios({
        url: "/admin/partner/reg/info/basic/edit",
        type: "MULTI",
        params: formData,
      });
      if (rs.result === "SUC") {
        await this.getBasicAdminData();
        this.isEdit = false;
      } else {
        console.log("기본정보 승인 신청 실패");
      }
    },
    async editBasicData() {
      const formData = new FormData();
      if (this.isEditData("phone")) {
        formData.append("phone", this.editData.phone);
      }
      if (this.isEditData("addr")) {
        formData.append("addr", this.editData.addr);
      }
      if (this.isEditData("addr2")) {
        formData.append("addr2", this.editData.addr2);
      }
      if (this.isEditData("highSpecialtyEsntlId")) {
        formData.append(
          "highSpecialtyEsntlId",
          this.editData.highSpecialtyEsntlId
        );
      }
      if (this.isEditData("lowSpecialtyEsntlId")) {
        formData.append(
          "lowSpecialtyEsntlId",
          this.editData.lowSpecialtyEsntlId
        );
      }
      if (this.isEditData("orderSpecialty")) {
        formData.append("orderSpecialty", this.editData.orderSpecialty);
      }
      if (this.isEditData("career")) {
        formData.append("career", this.editData.career);
      }
      if (this.isEditData("proofUrl")) {
        formData.append("proofUrl", this.editData.proofUrl);
      }
      if (this.isEditData("proofFile")) {
        formData.append("proofFile", this.editData.proofFile);
      }

      const rs = await this.$axios({
        url: "/partner/myInfo/basic/update",
        type: "MULTI",
        params: formData,
      });
      if (rs.result === "SUC") {
        await this.getBasicData();
        this.isEdit = false;
      } else {
        console.log("기본정보 승인 신청 실패");
      }
    },
    handleUpload(e) {
      if (e.target.files[0]) {
        this.editData.proofFile = e.target.files[0];
        this.editFileOriName = e.target.files[0].name;
      }
    },
    getApprovalStatus() {
      switch (this.approvalStatus) {
        case "승인완료":
          return "complete";
        case "승인거절":
          return "reject";
        case "처리중":
          return "process";
      }
    },
    formatPhone(num) {
      if (/^(02|0[3-9]{1}[0-9]{1})-?[0-9]{3}-?[0-9]{4,}$/.test(num)) {
        if ((/^(02)/.test(num) && num.length >= 10) || num.length >= 13) {
          return num
            .replace(/[-]/g, "")
            .replace(
              /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
              "$1-$2-$3"
            );
        }
        return num.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3})([0-9]{4})/,
          "$1-$2-$3"
        );
      }
      return num.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
        "$1-$2-$3"
      );
    },
    downloadFile() {
      const url = `https://medal.sailors.kr/partner/download?file=${this.infoData.proofFile}&oriFile=${this.infoData.proofFileOri}`;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", url); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    clickProofUrl(link) {
      let url = link;
      if (!link.includes("https")) {
        url = "https://" + link;
      }
      window.open(url);
    },
    clickCancel() {
      this.isEdit = false;
      this.isEditPhone = false;
      this.isFirst = true;
      this.isSend = false;
      this.isCheck = false;
      this.editData.phone = JSON.parse(JSON.stringify(this.phoneDummy));
      this.phoneCheck = "";
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  margin-top: 10px;
}
.info-header-wrap {
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.header-status-complete {
  color: #2CCDC3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-reject {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-process {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 16px 0;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  padding-left: 32px;
  width: 200px;
}
.business-info {
  font-weight: 500;
  font-size: 12px;
  color: #828699;
  margin-right: 10px;
}
</style>
