<template>
  <!-- 정산정보 -->
  <div class="info-container" v-if="infoData">
    <!-- 헤더 -->
    <div class="info-header-wrap flex-space-between">
      <div class="flex-align-center">
        <span class="header-category">정산정보</span>
        <div v-if="!isAdmin">
          <span :class="`header-status-${getApprovalStatus()}`">{{
            approvalStatus
          }}</span>
          <span
            class="header-status-date"
            v-if="approvalStatus === '처리중'"
            @click="openBankModal"
            >{{ regDate }}</span
          >
          <span
            class="header-status-date"
            @click="openBankModal"
            v-if="
              approvalStatus === '승인완료' || approvalStatus === '승인거절'
            "
            >{{ approvalDate }}</span
          >
          <span
            class="header-status-reason"
            v-if="approvalStatus === '승인거절'"
            @click="openRejectReason"
            >거절사유보기 ></span
          >
        </div>
      </div>
      <div class="flex-align-center" v-if="isEdit">
        <span
          class="mr-8"
          style="color: #f1431d; font-size: 12px"
          v-if="!checkValid"
          >모든 항목을 입력해주세요.</span
        >
        <button-common
          class="mr-8"
          :size="SIZE_T"
          :clr="CLR_W"
          @click="clickCancel"
          >취소</button-common
        >
        <button-common
          :disabled="!checkValid"
          :size="SIZE_T"
          :clr="CLR_M"
          @click="clickEdit"
          >수정신청</button-common
        >
      </div>
      <button-common :size="SIZE_T" :clr="CLR_M" @click="isEdit = true" v-else
        >수정</button-common
      >
    </div>
    <!-- 설명 -->
    <div class="info-notice flex" v-if="isEdit">
      <div class="info-notice-text" style="margin-right: 16px">
        계좌 유형을 선택하고 정산받으실 금융계좌를 등록해주세요.<br />
        정산예정일을 기준으로 최종 등록된 계좌로 정산됩니다.
      </div>
      <div>
        <div class="flex-align-center info-notice-text">
          <div class="div-circle" />
          사업자가 아닌 경우: 개인 명의의 금융계좌 등록
        </div>
        <div class="flex-align-center info-notice-text">
          <div class="div-circle" />
          개인 사업자인 경우: 대표자 개인명의의 금융계좌 등록
        </div>
        <div class="flex-align-center info-notice-text">
          <div class="div-circle" />
          법인 사업자인 경우 :법인명의의 금융계좌 등록
        </div>
      </div>
    </div>
    <!-- 정산방법 -->
    <div class="info-content-wrap" v-if="!isEdit">
      <span class="content-category">정산방법</span>
      <span>계좌입금</span>
    </div>
    <!-- 입금계좌 -->
    <div class="info-content-wrap" v-if="!isEdit">
      <span class="content-category">입금계좌</span>
      <span
        >{{ infoData.bankName }} {{ infoData.accountNumber }}(예금주:{{
          infoData.accountHolder
        }})</span
      >
    </div>
    <!-- 유형 -->
    <div class="info-content-wrap" v-if="isEdit">
      <span class="content-category">유형</span>
      <div>
        <select-box
          style="width: 240px; text-align: left"
          v-model="identityNumType"
          :opt-nm="SELT_MYINFO_ACCOUNT"
          :size="SIZE_M"
        />
      </div>
    </div>
    <!-- 예금주 -->
    <div class="info-content-wrap" v-if="isEdit">
      <span class="content-category">예금주</span>
      <div class="flex-align-center">
        <input-text
          placeholder="예금주명"
          v-model="editData.accountHolder"
          class="mr-16"
          style="width: 200px"
        />
        <span style="font-size: 12px; color: #828699"
          >개인(일반,간이)사업자의 예금주는 대표자명 또는 사업자등록증의
          상호명과 동일해야 하며<br />
          법인사업자의 예금주는 사업자등록증의 법인 명의와 동일해야
          합니다.</span
        >
      </div>
    </div>
    <!-- 생년월일 -->
    <div class="info-content-wrap" v-if="isEdit && identityNumType === 'P'">
      <span class="content-category">생년월일</span>
      <div class="flex-align-center">
        <input-text
          placeholder="생년월일 6자리 (YYMMDD)"
          v-model="identityNum"
          class="mr-16"
          style="width: 200px"
        />
      </div>
    </div>
    <!-- 사업자번호 -->
    <div class="info-content-wrap" v-if="isEdit && identityNumType === 'B'">
      <span class="content-category">사업자번호</span>
      <div class="flex-align-center">
        <input-text
          placeholder="사업자등록번호 10자리"
          v-model="identityNum"
          class="mr-16"
          style="width: 200px"
        />
      </div>
    </div>
    <!-- 은행선택 -->
    <div class="info-content-wrap" v-if="isEdit">
      <span class="content-category">은행선택</span>
      <div>
        <select-box
          style="width: 200px; text-align: left"
          v-model="editData.bankCode"
          :opt-nm="SELT_POPBILL_BANK"
          :size="SIZE_M"
        />
      </div>
    </div>
    <!-- 계좌 번호 -->
    <div class="info-content-wrap" v-if="isEdit">
      <span class="content-category">계좌 번호</span>
      <div class="flex-align-center">
        <input-text
          v-model="editData.accountNumber"
          class="mr-16"
          style="width: 200px"
          placeholder="입금받을 계좌번호 입력"
        />
        <button-common
          class="mr-16"
          :size="SIZE_T"
          :clr="CLR_M"
          :disabled="!checkAccountValid"
          @click="checkAccount"
          >계좌인증</button-common
        >
        <span
          class="business-info"
          style="color: #2ccdc3"
          v-if="!isFirst && isCheck"
          >인증완료</span
        >
        <span
          class="business-info"
          style="color: #fd765f"
          v-if="!isFirst && !isCheck"
          >인증실패</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BankInfo",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: "",
    },
  },

  /*** data ***/
  data() {
    return {
      infoData: null,
      previewEditData: null,
      identityNum: "",
      identityNumType: "P",
      editData: {
        accountHolder: "",
        bankCode: "",
        accountNumber: "",
        bankName: "",
      },
      isEdit: false,
      approvalStatus: null,
      approvalDate: null,
      regDate: null,
      rejectReason: "",
      isAdmin: false,
      isFirst: true,
      isCheck: false,
      isEditAccount: false,
    };
  },

  /*** created ***/
  async mounted() {
    if (this.$route.params.id) {
      this.isAdmin = true;
      await this.getBankAdminData();
    } else {
      this.isAdmin = false;
      await this.getBankData();
    }
  },

  /*** computed ***/
  computed: {
    checkValid() {
      return (
        this.editData.accountHolder &&
        this.editData.bankCode &&
        this.editData.accountNumber &&
        this.isCheck
      );
    },
    checkAccountValid() {
      return (
        this.editData.accountHolder &&
        this.editData.bankCode &&
        this.editData.accountNumber
      );
    },
  },

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    async checkAccount() {
      const rs = await this.$axios({
        url: "/partner/myInfo/bank/check",
        params: {
          name: this.editData.accountHolder,
          identityNumType: this.identityNumType,
          identityNum: this.identityNum,
          bankCode: this.editData.bankCode,
          accountNumber: this.editData.accountNumber,
        },
      });
      this.isFirst = false;
      this.isCheck = false;
      if (rs.result === "SUC") {
        this.isCheck = rs.resultCode === 100;
        if (rs.resultCode !== 100) {
          await this.openError(rs.resultCode);
        }
      }

      if (rs.result === "DUPL") {
        await this.openDuplicate();
      }
    },
    async openError(code) {
      await this.$store.dispatch("A_ERROR_CODE", code);
      await this.$openError({}, this);
    },
    async openDuplicate() {
      await this.$openDuplicate({}, this);
    },
    async getBankAdminData() {
      const rs = await this.$axios({
        url: "/admin/partner/reg/info",
        params: { esntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.infoData = rs.resultData[0];
        this.setEditData();
      } else {
        console.log("정산정보 조회 실패");
      }
    },
    cancelEditAccount() {
      this.isEditAccount = false;
      this.isFirst = true;
      this.isCheck = false;
    },
    async getBankData() {
      const rs = await this.$axios({
        url: "/partner/myInfo/bank/data",
        params: {},
      });
      if (rs.result === "SUC") {
        this.infoData = rs.resultData.infoData[0];
        this.previewEditData = rs.resultData.editData;
        if (this.previewEditData) {
          switch (this.previewEditData.approvalStatus) {
            case "APP":
              this.approvalStatus = "승인완료";
              break;
            case "REJ":
              this.approvalStatus = "승인거절";
              break;
            default:
              this.approvalStatus = "처리중";
          }
          this.approvalDate = this.previewEditData.approvalDate;
          this.regDate = this.previewEditData.regDate;
          this.rejectReason = this.previewEditData.rejectReason;
          // this.setEditData();
        } else {
          this.approvalStatus = null;
          this.approvalDate = null;
        }
      } else {
        console.log("정산정보 조회 실패");
      }
    },
    setEditData() {
      this.editData.accountHolder = this.infoData?.accountHolder;
      this.editData.accountNumber = this.infoData?.accountNumber;
      this.editData.bankName = this.infoData?.bankName;
    },
    async openBankModal() {
      await this.$openBankInfo({}, this);
    },
    async openRejectReason() {
      this.$store.dispatch("A_REJECT_REASON_DISABLED", true);
      this.$store.dispatch("A_REJECT_REASON", this.rejectReason);
      await this.$openRejectReason({}, this);
    },
    async clickEdit() {
      if (this.$route.params.id) {
        await this.editBankAdminData();
      } else {
        await this.editBankData();
      }
    },
    async editBankAdminData() {
      const data = JSON.parse(JSON.stringify(this.editData));
      data.bankName = this.SELT_POPBILL_BANK.filter(
        (item) => item.value === this.editData.bankCode
      )[0].title;
      data.regDate = this.infoData.regDate;
      data.esntlId = this.$route.params.id;
      const rs = await this.$axios({
        url: "/admin/partner/reg/info/bank/edit",
        params: data,
      });
      if (rs.result === "SUC") {
        await this.getBankAdminData();
        this.clickCancel();
      } else {
        console.log("정산 승인 신청 실패");
      }
    },
    async editBankData() {
      this.editData.bankName = this.SELT_POPBILL_BANK.filter(
        (item) => item.value === this.editData.bankCode
      )[0].title;
      const rs = await this.$axios({
        url: "/partner/myInfo/bank/update",
        params: this.editData,
      });
      if (rs.result === "SUC") {
        await this.getBankData();
        this.clickCancel();
      } else {
        console.log("정산 승인 신청 실패");
      }
    },
    getApprovalStatus() {
      switch (this.approvalStatus) {
        case "승인완료":
          return "complete";
        case "승인거절":
          return "reject";
        case "처리중":
          return "process";
      }
    },
    clickCancel() {
      this.isEdit = false;
      this.isEditAccount = false;
      this.isFirst = true;
      this.isCheck = false;
      this.editData = {
        accountHolder: "",
        bankCode: "",
        accountNumber: "",
        bankName: "",
      };
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  margin-top: 10px;
}
.info-header-wrap {
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid #d8dae6;
}
.info-notice {
  background: #f8f8fb;
  padding: 8px 32px;
}
.info-notice-text {
  font-size: 12px;
  color: #828699;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.header-status-complete {
  color: #2CCDC3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-reject {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-process {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 16px 0;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  padding-left: 32px;
  width: 200px;
}
.div-circle {
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: #dddddd;
  margin-right: 4px;
}
.business-info {
  font-weight: 500;
  font-size: 12px;
  color: #828699;
}
</style>
